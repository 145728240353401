import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import config from 'config/config';
import { closeModal, showLoginModal } from 'src/modules/shared/modal/actions';
import i18n from 'src/utils/translate';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import SignupForm from 'src/modules/shared/modal/components/signup/signupForm';
import { Container, WordWithLine } from 'src/modules/shared/modal/components/login/loginBox';
import SocialLoginButtons from 'src/modules/shared/modal/components/login/socialLoginButtons';
import { Community } from 'src/constants/types';
import Spacer, { Size as SpacerSize } from 'src/components/spacer';
import { Close } from 'src/components/modals/modal';
import Avatar, { Size as AvatarSize } from 'src/components/avatar';
import { titleLarge } from 'styles-js/mixins/typography';
import { LinkButton } from 'styles-js/buttons';
type Props = {
  signupCommunity?: Community | null;
  redirectPage?: () => void;
  onSignupSuccess?: () => void;
};
export default function SignupBox({
  signupCommunity,
  redirectPage,
  onSignupSuccess
}: Props) {
  const dispatch = useDispatch();
  const currentCommunity = useSelector(({
    context
  }) => context.currentCommunity);
  const community = signupCommunity || currentCommunity;
  const isCommunitySignup = community ? !community.isArchived : false;
  const heading = isCommunitySignup ? community?.name + ' ' + i18n.t('on HealthUnlocked') : i18n.t(`Join the world's largest social network for health`);
  const onClickLogin = async () => {
    await dispatch(closeModal());
    dispatch(showLoginModal({
      onLoginSuccess: onSignupSuccess
    }));
  };
  return <Container data-sentry-element="Container" data-sentry-component="SignupBox" data-sentry-source-file="signupBox.tsx">
      <Close showText={false} data-sentry-element="Close" data-sentry-source-file="signupBox.tsx" />
      <Body data-sentry-element="Body" data-sentry-source-file="signupBox.tsx">
        <Logos data-sentry-element="Logos" data-sentry-source-file="signupBox.tsx">
          <img alt="HealthUnlocked logo" height={60} src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} width={60} />
          {isCommunitySignup && <CommunityLogo alt={`${community?.name} logo`} size={AvatarSize.Small} url={getCommunityLogoUrl(community?.logoImageHash)} />}
        </Logos>
        <Spacer size={SpacerSize.L} data-sentry-element="Spacer" data-sentry-source-file="signupBox.tsx" />
        <h2>{heading}</h2>
        <SocialLoginButtons isCommunitySignup={isCommunitySignup} redirectPage={redirectPage} onLoginSuccess={onSignupSuccess} data-sentry-element="SocialLoginButtons" data-sentry-source-file="signupBox.tsx" />
        <WordWithLine data-sentry-element="WordWithLine" data-sentry-source-file="signupBox.tsx"><span>{i18n.t('OR')}</span></WordWithLine>
        <SignupForm isCommunitySignup={isCommunitySignup} redirectPage={redirectPage} onSignupSuccess={onSignupSuccess} data-sentry-element="SignupForm" data-sentry-source-file="signupBox.tsx" />
        <Spacer size={SpacerSize.L} data-sentry-element="Spacer" data-sentry-source-file="signupBox.tsx" />
        <LinkButton onClick={onClickLogin} data-sentry-element="LinkButton" data-sentry-source-file="signupBox.tsx">{i18n.t('Already a member?')} {i18n.t('Log in')}</LinkButton>
      </Body>
    </Container>;
}
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    ${titleLarge}
    text-align: center;
  }
`;
const Logos = styled.div`
  position: relative;
`;
const CommunityLogo = styled(Avatar)`
  position: absolute;
  bottom: -2px;
  right: -20px;
  border: 2px solid ${({
  theme
}) => theme.colorWhite};
  box-shadow: ${({
  theme
}) => theme.shadowDefault};
`;